<template>
  <div class="mt-5">
    <div>
      <v-data-table
          :headers="headers"
          :items="dataSource"
          :mobile-breakpoint="200"
          :loading="loaded"
          :options.sync="options"
          :search="search"
          :server-items-length="totalItems"
          class="mt-5"
          item-key="uuid"
          hide-default-header
          :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
      >

        <template v-slot:top>
          <div>
            <v-toolbar
                flat
            >
              <v-toolbar-title>Kardex</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="dialog"
                  max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-if="hasAccess('/kardex', 'POST')"
                      @click="createKardex"
                      rounded
                      class="btn-active ma-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    Agregar
                  </v-btn>
                </template>
              </v-dialog>
            </v-toolbar>
            <div class="mx-4 mb-4">
              <v-text-field
                  v-model="search"
                  :label="label"
                  hide-details
                  outlined
                  prepend-icon="mdi-magnify"
                  @click:append="cleanText"
                  :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
                  single-line
                  @input="typingSearch"
              ></v-text-field>
            </div>
          </div>
        </template>

        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
          <tr>
            <th  :style="{ minWidth: header.minWidth || 'auto' }" class="text-start" v-for="header in props.headers" :key="header.value">
                    <span>
                        {{ header.text }}
                    </span>
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item.productUnit.product.name }} ({{ item.productUnit.unit.name }})</span>
            </td>
            <td>
              <span>{{ item.quantity }}</span>
            </td>
            <td>
              <span>{{ getFrequencyName(item) }} {{getDays(item.daysOfWeek)}} {{getMonths(item.monthsOfYear)}}</span>
            </td>
            <td>
              <span>{{ getSubFrequency(item.subFrequency) }}</span>
            </td>
            <td>
              <span>{{ getDosis(item.doseType) }}</span>
            </td>
            <td>
              <span>{{ formatDate(item.startDateTime) }}</span>
            </td>
            <td>
              <span>{{ formatDate(item.endDateTime) }}</span>
            </td>
            <td>
              <span>{{ item.weeklyPrediction }}</span>
            </td>
            <td>
              <span>{{ item.monthlyPrediction }}</span>
            </td>
            <td>
              <v-icon
                  :disabled="!hasAccess('/kardex', 'GET')"
                  small
                  class="mr-2"
                  @click="editRow(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  :disabled="!hasAccess('/kardex', 'DELETE')"
                  small
                  @click="deleteRow(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Kardex'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
        @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import ActionPopUp from "../../components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
import tableMixin from "../../mixins/tableMixin";
import moment from "moment";

export default {
  name: "InventoryPatientCardexResume",
  components: {ActionPopUp},
  mixins: [moduleMixin, tableMixin],
  props: {
    patientId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      module: `kardex`,
      label: "Busca por producto",
      frequencies: {
        every_x_weeks: 'Cada X semanas',
        every_x_months: 'Cada X meses',
        as_needed: 'Por razón necesaria',
        alternate_day: 'Un día sí y un día no',
        days_of_week: 'Días específicos de la semana',
        months_of_year: 'Meses específicos del año',
        every_x_hours: 'Cada X horas',
        every_x_days: 'Cada X días'
      },
      subFrequencies: {
        twice_per_day: 'Dos veces al día',
        three_times_per_day: 'Tres veces al día',
        more_than_three_times_per_day: 'Más de tres veces al día',
        once_per_day: 'Una vez al día'
      },
      doseTypes: {
        until_end: 'Hasta término',
        continuous: 'Sin suspender',
        periodic: 'Periódica'
      },
      months: {
        january: 'Enero',
        february: 'Febrero',
        march: 'Marzo',
        april: 'Abril',
        may: 'Mayo',
        june: 'Junio',
        july: 'Julio',
        august: 'Agosto',
        september: 'Septiembre',
        october: 'Octubre',
        november: 'Noviembre',
        december: 'Diciembre'
      },
      days: {
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo'
      },
      periodicDays: {
        morning: 'Mañana',
        afternoon: 'Tarde',
        night: 'Noche',
        other: 'Otro'
      }
    };
  },
  created() {
    this.embedded = 'ProductUnit.Product,ProductUnit.Unit'
    this.headers = [
      {
        text: "Producto",
        sortable: true,
        value: "product",
      },
      {
        text: "Cantidad",
        sortable: true,
        value: "quantity",
      },
      {
        text: "Frecuencia",
        sortable: true,
        value: "dosis",
      },
      {
        text: "Sub-Frecuencia",
        sortable: true,
      },
      {
        text: "Dosis",
        sortable: true,
      },
      {
        text: "Inicio",
        sortable: true,
      },
      {
        text: "Fin",
        sortable: true,
      },
      {
        text: "Predicción Semanal",
        sortable: true,
      },
      {
        text: "Predicción mensual",
        sortable: true,
      },
      {
        minWidth: '80px',
        text: "",
        sortable: true,
      },
    ];
  },
  methods: {
    createKardex() {
      this.$router.push(`/crudkardex/create/${this.patientId}/${null}`);
    },
    editRow(item) {
      this.$router.push(`/crudkardex/edit/${this.patientId}/${item.uuid}`);
    },
    getFrequencyName(item) {
      if (this.frequencies[item?.frequency] === 'Cada X días'){
        let periodicDay = ''
        if (item?.dailyDoseTime) {
           periodicDay = this.periodicDays[item?.dailyDoseTime]
          if (periodicDay === 'Otro') {
            return `Cada día a las ${item?.doseTime}`
          }
        }else if (item?.daysInterval) {
          return `Cada ${item?.daysInterval} veces al día`
        }
        return `${this.frequencies[item?.frequency]} - En la ${periodicDay}`
      }else if (this.frequencies[item?.frequency] === 'Cada X horas') {
        return  `Cada ${item?.hoursInterval} horas`
      }
      return this.frequencies[item?.frequency]
    },
    getSubFrequency(subFrequency) {
      return this.subFrequencies[subFrequency]
    },
    getDosis(dosis) {
      return this.doseTypes[dosis]
    },
    getDays(day) {
      if (!day) {
        return ''
      }
      let newDays = day.split(',')
      newDays = newDays.map((item) => this.days[item])
      return newDays.join(',')
    },
    getMonths(month) {
      if (!month) {
        return ''
      }
      let newMonth = month.split(',')
      newMonth = newMonth.map((item) => this.months[item])
      return newMonth.join(',')
    },
    formatDate(time) {
      if (!time) return ''
      return moment(time).format('DD/MM/YYYY')
    }
  },
};
</script>

<style scoped></style>
