import customStoreService from "@/services/customStore";
import {mapState} from "vuex";

let typing;
export default {
    props: {
        isWizard: {type: Boolean, default: false},
        isInvoice: {type: Boolean, default: false},
        elementId: {type: String, default: null},
    },
    data() {
        return {
            dataSource: [],
            headers: [],
            module: "",
            category: '',
            expanded: [],
            singleExpand: true,
            search: "",
            label: "Escribe el nombre para buscar",
            dialog: false,
            selectedRow: {},
            loaded: false,
            type: "error",
            totalItems: 0,
            options: {},
            hasProperty: false,
            message: '',
            embedded:'',
            additionalParams:{},
            status:"",
            orderParam:[],
            queryOrderParam:'',
        };
    },
    computed: {
        ...mapState("auth", ["user", "resources"]),
        ...mapState('contract', ['currentContract']),
    },
    watch: {
        options: {
            async handler() {
                await this.loadData();
            },
            deep: true,
        },
    },
    methods: {
       async cleanText() {
            this.search = ""
            await this.loadData();
        },
        async loadData() {

            try {
                this.afterProcess();
                this.loaded = true;
                if (!this.hasProperty) {
                    const {page, itemsPerPage} = this.options;
                    let result;
                    let order = '';
                    if (this.options && this.options.sortBy && this.options.sortBy.length > 0) {
                        order = `${this.options.sortDesc[0] ? '-' : ''}${this.options.sortBy[0]}`
                    }
                    if (
                        this.isWizard
                        && this.module === 'vehicles'
                        && this.currentContract.startDateTime !== ''
                        && this.currentContract.endDateTime !== ''
                    ) {
                        const startDate = new Date(`${this.currentContract.startDateTime} ${this.currentContract.startTime}`);
                        const endDate = new Date(`${this.currentContract.endDateTime} ${this.currentContract.endTime}`);
                        result = await customStoreService.getDataByModule(
                            this.module,
                            this.search,
                            page,
                            itemsPerPage,
                            startDate.toISOString(),
                            endDate.toISOString(),
                            'contractCost',

                            order
                        );
                    } else {
                        result = await customStoreService.getDataByModuleAndParam(
                            this.module,
                            this.search,
                            page,
                            itemsPerPage,
                            '',
                            '',
                            this.embedded,
                            this.additionalParams,
                            this.status,
                            this.queryOrderParam
                        );
                    }
                    this.dataSource = result.data;
                    this.totalItems = result.totalCount;
                } else {
                    let result;
                    result = await customStoreService.getDataByProperty(
                        this.module,
                        this.category,
                        this.elementId,
                    );
                    if (result) {
                        this.dataSource = result.data;
                        this.totalItems = result.totalCount;
                    }

                }
                this.postProcessing();
                this.loaded = false;

            } catch(error) {
                this.type = "error";
                this.dialog = true;
                this.message = `${error?.response?.data?.message || ''} ${error?.response?.data?.details || ''}`
            }
        },
        afterProcess() {
        },
        postProcessing() {
        },
        deleteRow(row) {
            this.type = "delete";
            this.selectedRow = row;
            this.dialog = true;
        },
        editRow(row, pathName, crudAction, origin = null, optionalParam = null) {
            this.$router.push({
                name: pathName,
                params: {crudAction: crudAction, id: row.uuid ?? optionalParam, origin: origin},
            });
        },
        async deleteAction() {
            try {
                await customStoreService.deleteByModule(this.module, this.selectedRow);
                await this.loadData();
                this.dialog = false;
            } catch (error) {
                this.message = `${error?.response?.data?.message || ''} ${error?.response?.data?.details || ''}`
            }
        },
        displayArrayToString(row) {
            if (row?.length) {
                return row.map((item) => item).join();
            }
            return "No role assign it";
        },
        formatAddress(address) {
            return `${address?.street || ""} ${address?.city} ${address?.state}, ${
                address?.zipCode
            }`;
        },
        displayStatus(status) {
            if (status) {
                return "Active";
            }
            return "Disabled";
        },
        closeAction() {
            this.dialog = false;
        },
        typingSearch() {
           this.options.page = 0
            clearInterval(typing);
            typing = setInterval(
                async function () {
                    await this.loadData();
                    clearInterval(typing);
                }.bind(this),
                500
            );
        },
        selectRowStepper(item) {
            console.log(item)
        },
        hasAccess(module, type) {
            let hasAccess = false;
            this.resources.forEach((itemRes) => {
                if (itemRes?.resourceName === module) {
                    if (itemRes.permission === type) {
                        hasAccess = true;
                    }
                }
            });
            return hasAccess;
        },
        async bellInvoice(row) {
            try {
                await customStoreService.postDataByModuleAndUrl('invoices/sign', row.uuid)
                await this.loadData()
                this.type = "success";
                this.dialog = true;
            } catch (error) {
                this.type = "error";
                this.dialog = true;
                this.message = `${error?.response?.data?.message || ''} ${error?.response?.data?.details || ''}`
            }
        },
        async sendEmail(row) {
            try {
                await customStoreService.getDataCrudByModule('invoices/recovery', row?.uuid)
                this.type = "success";
                this.dialog = true;
            } catch (error) {
                this.type = "error";
                this.dialog = true;
                this.message = `${error?.response?.data?.message || ''} ${error?.response?.data?.details || ''}`
            }
        },
        async cancelInvoice(row) {
            try {
                this.selectedInvoice = row
                if (row.useCfdi !== 'I01') {
                    const data = {
                        uuid: row.uuid,
                        cancelType: row.useCfdi
                    }
                    await customStoreService.postDataByModule('invoices/cancel', data)
                    await this.loadData()
                    this.type = "success";
                    this.dialog = true;
                } else {
                    this.openCancelCFDI = true
                }
            } catch (error) {
                this.type = "error";
                this.dialog = true;
                this.message = `${error?.response?.data?.message || ''} ${error?.response?.data?.details || ''}`
            }
        },
       async handleHeaderClick(column) {
           if (!column?.text) {
               return
           }
            let params = ''
            if (this.orderParam.length) {
               const orderFound = this.orderParam.findIndex((item)=> item.text === column.text)
                if (orderFound === -1 ) {
                    this.orderParam.push(column)
                }else {
                    let lastOrderParam =  this.orderParam[orderFound].value
                     if (lastOrderParam.includes('-')) {
                         lastOrderParam = lastOrderParam.replace(/^[-\s]+/, '');
                        }else {
                         lastOrderParam = `-${lastOrderParam}`
                        }
                    this.orderParam[orderFound].value = lastOrderParam
                }
            }else {
                this.orderParam.push(column)
            }
             params = this.orderParam.map((item, index) => {
                const prefix = index === 0 ? 'order' : '&order';
                return `${prefix}=${item.value}`;
            }).join('');
            this.queryOrderParam = params
           await this.loadData()
        }
    },
};
