<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <top-info-action
        principal-btn-title="Nueva orden de inventario"
        subtitle="Crea, busca, filtra y visualiza órdenes de inventario"
        title="Órdenes de inventario por paciente"
        @setDefaultPrimaryAction="createNewStock"
    ></top-info-action>
    <patient-stock-resume></patient-stock-resume>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import TopInfoAction from "@/components/TopInfoAction.vue";
import moduleMixin from "@/mixins/moduleMixin";
import PatientStockResume from "../components/PatientsStock/PatientStockResume.vue";


export default {
  name: "PatientStock",
  mixins: [moduleMixin],
  components: {PatientStockResume, TopInfoAction, Breadcrumbs},
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: false,
        path: "/dashboard",
      },
      {
        text: "Órdenes de inventario por paciente",
        disabled: true,
        path: "/patients-stocks",
      },
    ],
  }),
  methods: {
    createNewStock() {
      this.$router.push({
        name: "CrudPatientStock",
        params: {crudAction: "create"},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
