<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
          <store-select
              :available-items="storesByEmployee"
              :is-one-element="false"
              :multiple-select="true"
              @selectedStore="selectedStore"
          ></store-select>
        </v-col>
        <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
          <div class="display-item-center">
            <UploadFiles
                v-if="currentElement.uuid"
                :owner-id="currentElement.uuid"
                @downloadFiles="downloadFiles"
            ></UploadFiles>
          </div>
        </v-col>
      </v-row>
      <v-form v-model="validForm" @input="inputForm">
        <v-row no-gutters>
          <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
            <title-info-card
                icon="mdi-face-man-outline"
                textTitle="Información personal"
            >
              <template #contentCard>
                <v-row class="mt-2">
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <div>
                      <label class="input-label" for="Name*"
                      >Nombre<span class="required-field-form">*</span></label
                      >
                      <v-text-field
                          id="name"
                          v-model="currentElement.name"
                          :rules="required"
                          color="backofficeInput"
                          hide-details
                          outlined
                          placeholder="Ejemplo: Juan"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <div>
                      <label class="input-label" for="Last Name*"
                      >Apellidos<span class="required-field-form"
                      >*</span
                      ></label
                      >
                      <v-text-field
                          id="lastName"
                          v-model="currentElement.lastName"
                          :rules="required"
                          color="backofficeInput"
                          hide-details
                          outlined
                          placeholder="Ejemplo: Hernandez"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <div>
                      <label class="input-label" for="email*"
                      >E-mail<span class="required-field-form">*</span></label
                      >
                      <v-text-field
                          id="email"
                          v-model="currentElement.email"
                          :rules="required"
                          color="backofficeInput"
                          hide-details
                          outlined
                          placeholder="Ejemplo: jhon.s@backoffice.com"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <div>
                      <label class="input-label" for="Phone*"
                      >Teléfono<span class="required-field-form">*</span></label
                      >
                      <v-text-field
                          id="licensePlate"
                          v-model="currentElement.phone"
                          :rules="required"
                          color="backofficeInput"
                          hide-details
                          outlined
                          placeholder="Ejemplo: +5425512345678"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <div>
                      <v-menu
                          ref="menu"
                          v-model="menu1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          min-width="290px"
                          offset-y
                          transition="scale-transition"
                      >
                        <template v-slot:activator="{ on }">
                          <label class="input-label" for="Birthday"
                          >Año de nacimiento<span class="required-field-form"
                          >*</span
                          ></label
                          >
                          <v-text-field
                              id="Birthday"
                              :rules="birthday"
                              :value="currentElement.birthday"
                              hide-details
                              outlined
                              placeholder="Ejemplo: 1995-07-26"
                              readonly
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="currentElement.birthday"
                            scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn color="primary" text @click="menu1 = false"
                          >Cancelar
                          </v-btn>
                          <v-btn
                              color="primary"
                              text
                              @click="$refs.menu.save(date)"
                          >OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                  <v-col
                      v-if="!hideField"
                      cols="12"
                      lg="12"
                      md="12"
                      sm="12"
                      xl="12"
                      xs="12"
                  >
                    <div>
                      <label class="input-label" for="Password*"
                      >Contraseña
                        <span class="required-field-form"
                        >*</span
                        ></label
                      >
                      <v-text-field
                          id="Password"
                          v-model="currentElement.password"
                          :rules="required"
                          color="backofficeInput"
                          hide-details
                          outlined
                          placeholder="Ejemplo: 123@qwerty!"
                      />
                    </div>
                  </v-col>
                </v-row>
              </template>
            </title-info-card>
          </v-col>
        </v-row>
        <v-row class="mt-10 mb-10" no-gutters>
          <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
            <title-info-card
                icon="mdi-text-box-check-outline"
                textTitle="Roles y permisos"
            >
              <template #contentCard>
                <v-row class="mt-2">
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <select-cards
                        :available-items="rolesByEmployee"
                        :available-option="availableRoles"
                        :isArrayObject="true"
                        info="Roles"
                        isRequired
                        property-name="name"
                        @selectedItem="selectedItem"
                    ></select-cards>
                  </v-col>
                </v-row>
              </template>
            </title-info-card>
          </v-col>
        </v-row>
        <v-row class="mt-10" no-gutters>
          <v-col ccols="12" lg="12" md="12" sm="12" xl="12" xs="12">
            <title-info-card icon="mdi-home-outline" textTitle="Dirección">
              <template #contentCard>
                <v-row class="mt-2">
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <div>
                      <label class="input-label" for="Street">Calle </label>
                      <v-text-field
                          id="Street"
                          v-model="currentElement.address.street"
                          color="backofficeInput"
                          hide-details
                          outlined
                          placeholder="Ejemplo: Siempre viva #24, Doctores"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <div>
                      <label class="input-label" for="City">Ciudad </label>
                      <v-text-field
                          id="City"
                          v-model="currentElement.address.city"
                          color="backofficeInput"
                          hide-details
                          outlined
                          placeholder="Ejemplo: California"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <div>
                      <label class="input-label" for="State">Estado</label>
                      <v-text-field
                          id="State"
                          v-model="currentElement.address.state"
                          color="backofficeInput"
                          hide-details
                          outlined
                          placeholder="Ejemplo: Jalisco"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <div>
                      <label class="input-label" for="Zip Code">Código postal</label>
                      <v-text-field
                          id="Zip Code"
                          v-model="currentElement.address.zipCode"
                          color="backofficeInput"
                          hide-details
                          outlined
                          placeholder="Ejemplo: 58200"
                      />
                    </div>
                  </v-col>
                </v-row>
              </template>
            </title-info-card>
          </v-col>
        </v-row>
      </v-form>
      <v-row v-if="currentElement.uuid" class="mt-5">
        <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
          <show-files-uploaded
              ref="showFiles"
              :owner-id="currentElement.uuid"
              :selected-img="currentElement.photoImageName"
              msg-info="Por favor selecciona una imagen o toma una foto"
              @reloadData="downloadFiles"
              @selectImage="selectImage"
          ></show-files-uploaded>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import TitleInfoCard from "@/components/TitleInfoCard.vue";
import {required, birthday} from "@/utils/validationRule";
import SelectCards from "@/components/SelectCard.vue";
import UploadFiles from "@/components/UploadFiles.vue";
import ShowFilesUploaded from "@/components/ShowFilesUploaded.vue";
import StoreSelect from "@/components/StoreSelect.vue";

export default {
  name: "FormVehicle",
  components: {StoreSelect, ShowFilesUploaded, UploadFiles, TitleInfoCard, SelectCards},
  props: {
    currentElement: {
      type: Object,
      default: () => {
      },
    },
    availableRoles: {
      type: Array,
      default: () => [],
    },
    rolesByEmployee: {
      type: Array,
      default: () => [],
    },
    storesByEmployee: {
      type: Array,
      default: () => [],
    },
    hideField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      birthday,
      validForm: false,
      menu1: false,
      date: null,
    };
  },
  methods: {
    selectedItem(e) {
      this.$emit("selectedItem", e);
    },
    inputForm(e) {
      this.$emit("validFormInput", e);
    },
    downloadFiles() {
      if (this.$refs.showFiles) {
        this.$refs.showFiles.getFilesByOwnerId();
      }
    },
    selectImage(imageName) {
      this.$emit("selectImage", imageName);
    },
    selectedStore(store) {
      this.$emit('selectedStore', store)
    }
  },
};
</script>

<style scoped></style>
