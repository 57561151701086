<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <title-info-card icon="mdi-google-spreadsheet" textTitle="Kardex">
            <template #contentCard>
              <v-form v-model="validForm" @input="inputForm">
                <v-row class="mt-3">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="product">Producto<span class="required-field-form">*</span></label>
                      <v-autocomplete
                          v-model="currentElement.productId"
                          id="product"
                          :items="products"
                          :rules="required"
                          item-value="uuid"
                          item-text="name"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Selecciona un producto"
                          @change="getProductUnits"
                      />
                    </div>
                  </v-col>
                  <v-col
                      cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="phone">Unidad<span
                          class="required-field-form">*</span></label>
                      <v-select
                          v-model="currentElement.productUnitId"
                          id="phone"
                          :items="productUnits"
                          :rules="required"
                          item-value="uuid"
                          item-text="unit.name"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Selecciona una unidad de producto"
                      />
                    </div>
                  </v-col>
                  <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label>Cantidad<span
                        class="required-field-form">*</span></label>
                  </v-col>
                  <v-col class="pt-0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-radio-group hide-details v-model="addManually" @change="selectManually">
                      <v-radio :value="setQuantity" label="Ingresar manualmente"></v-radio>
                    </v-radio-group>
                    <v-radio-group class="mt-1" v-model="currentElement.quantity" @change="selectQuantity">
                      <v-radio :value="0.25" label="1/4"></v-radio>
                      <v-radio :value="0.50" label="1/2"></v-radio>
                      <v-radio :value="1" label="1"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col v-if="addManually" class="pt-0"
                         cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <div>
                      <label class="input-label" for="street">Cantidad<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.quantity"
                          id="street"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Ingresa la cantidad"
                      />
                    </div>
                  </v-col>
                  <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label>Frecuencia<span
                        class="required-field-form">*</span></label>
                  </v-col>
                  <v-col class="pb-0" v-for="(item, index) in frequencies" :key="index" cols="12" xs="12" sm="6" md="4"
                         lg="4" xl="4">
                    <v-radio-group class="mt-0" v-model="currentElement.frequency" @change="cleanFrequency">
                      <v-radio :value="item.key" :label="item.value"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col v-if="currentElement.frequency === 'every_x_days'" cols="12" xs="12" sm="12" md="12" lg="12"
                         xl="12">
                    <v-row>
                      <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label>Cada X días<span
                            class="required-field-form">*</span></label>
                      </v-col>
                      <v-col class="pb-0" v-for="(item, index) in subFrequencies" :key="index" cols="12" xs="12" sm="6"
                             md="4" lg="4" xl="4">
                        <v-radio-group class="mt-0" v-model="currentElement.subFrequency" @change="cleanDaysInterval">
                          <v-radio :value="item.key" :label="item.value"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col v-if="currentElement.subFrequency === 'more_than_three_times_per_day'" cols="12" xs="12"
                             sm="12"
                             md="12" lg="12" xl="12">
                        <div>
                          <label class="input-label" for="street">Más de 3 veces al día<span
                              class="required-field-form">*</span></label>
                          <v-text-field
                              v-model="currentElement.daysInterval"
                              id="street"
                              :rules="required"
                              hide-details
                              outlined
                              color="backofficeInput"
                              placeholder="Ingresa la cantidad"
                          />
                        </div>
                      </v-col>
                      <v-col v-if="currentElement.subFrequency === 'once_per_day'" cols="12" xs="12" sm="12" md="12"
                             lg="12" xl="12">
                        <v-row>
                          <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label>Una vez al día<span
                                class="required-field-form">*</span></label>
                          </v-col>
                          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <v-radio-group hide-details class="mt-0" v-model="currentElement.dailyDoseTime"
                                           @change="selectDailyDoses">
                              <v-radio value="morning" :label="'Mañana'"></v-radio>
                              <v-radio value="afternoon" :label="'Tarde'"></v-radio>
                              <v-radio value="night" :label="'Noche'"></v-radio>
                              <v-radio value="other" :label="'Otro'"></v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col v-if="currentElement.dailyDoseTime === 'other'" class="pt-0"
                                 cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <div>
                              <label class="input-label" for="street">Otro<span
                                  class="required-field-form">*</span></label>
                              <v-menu
                                  ref="menu2"
                                  v-model="menu2"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="currentElement.doseTime"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="currentElement.doseTime"
                                      label="Escoge una hora"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      :rules="required"
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="menu2"
                                    v-model="currentElement.doseTime"
                                    full-width
                                    @click:minute="$refs.menu2.save(currentElement.doseTime)"
                                ></v-time-picker>
                              </v-menu>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="currentElement.frequency === 'days_of_week'" cols="12" xs="12" sm="12"
                         md="12" lg="12" xl="12">
                    <v-row>
                      <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label>Días específicos de la semana<span
                            class="required-field-form">*</span></label>
                      </v-col>
                      <v-col class="pb-0" v-for="(item, index) in days" :key="index" cols="12" xs="12" sm="6" md="4"
                             lg="4" xl="4">
                        <v-checkbox
                            v-if="selectedDays && selectedDays.length"
                            hide-details
                            v-model="selectedDays[index].value"
                            :label="`${item.value}`"
                            @change="selectDays($event,item,index)"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="currentElement.frequency === 'months_of_year'" cols="12" xs="12" sm="12"
                         md="12" lg="12" xl="12">
                    <v-row>
                      <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label>Meses específicos del año<span
                            class="required-field-form">*</span></label>
                      </v-col>
                      <v-col class="pb-0" v-for="(item, index) in months" :key="index" cols="12" xs="12" sm="6" md="4"
                             lg="4" xl="4">
                        <div v-if="selectedMonth && selectedMonth.length">
                          <v-checkbox
                              hide-details
                              v-model="selectedMonth[index].value"
                              :label="`${item.value}`"
                              @change="selectMonths($event,item,index)"
                          ></v-checkbox>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="currentElement.frequency === 'every_x_hours'" cols="12" xs="12" sm="12" md="12" lg="12"
                         xl="12">
                    <div>
                      <label class="input-label" for="street">Ingresa la cantidad de horas<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.hoursInterval"
                          id="street"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Ingresa la cantidad de horas"
                      />
                    </div>
                  </v-col>
                  <v-col v-if="currentElement.frequency === 'every_x_months'" cols="12" xs="12" sm="12" md="12" lg="12"
                         xl="12">
                    <div>
                      <label class="input-label" for="street">Ingresa la cantidad de meses<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.monthsInterval"
                          id="street"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Ingresa la cantidad de meses"
                      />
                    </div>
                  </v-col>
                  <v-col v-if="currentElement.frequency === 'every_x_weeks'" cols="12" xs="12" sm="12" md="12" lg="12"
                         xl="12">
                    <div>
                      <label class="input-label" for="street">Ingresa la cantidad de semanas<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.weeksInterval"
                          id="street"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Ingresa la cantidad de semanas"
                      />
                    </div>
                  </v-col>
                  <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label>Tipo de toma<span
                        class="required-field-form">*</span></label>
                  </v-col>
                  <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-row>
                      <v-col class="pb-0" v-for="(item, index) in doseTypes" :key="index" cols="12" xs="12" sm="6"
                             md="4"
                             lg="4" xl="4">
                        <v-radio-group class="mt-0" v-model="currentElement.doseType" @change="cleanDoseType">
                          <v-radio :value="item.key" :label="item.value"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="currentElement.doseType === 'periodic'" cols="12" xs="12" sm="12" md="12" lg="12"
                         xl="12">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <div>
                          <label class="input-label" for="street">Fecha de inicio<span
                              class="required-field-form">*</span></label>
                          <v-text-field
                              v-model="currentElement.startDateTime"
                              id="street"
                              :rules="required"
                              hide-details
                              outlined
                              color="backofficeInput"
                              placeholder="DD/MM/YYYY"
                              @keyup="dateFormatter"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <div>
                          <label class="input-label" for="street">Fecha fin<span
                              class="required-field-form">*</span></label>
                          <v-text-field
                              v-model="currentElement.endDateTime"
                              id="street"
                              :rules="required"
                              hide-details
                              outlined
                              color="backofficeInput"
                              placeholder="DD/MM/YYYY"
                              @keyup="dateFormatter"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="currentElement.doseType === 'until_end'" cols="12" xs="12" sm="12" md="12" lg="12"
                         xl="12">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><label>Cantidad de pildoras<span
                          class="required-field-form">*</span></label></v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <div>
                          <label class="input-label" for="street">Pildoras<span
                              class="required-field-form">*</span></label>
                          <v-text-field
                              v-model="currentElement.pillCount"
                              id="street"
                              :rules="required"
                              hide-details
                              outlined
                              color="backofficeInput"
                              placeholder="Ingresa la cantidad de pildoras"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </title-info-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import TitleInfoCard from "../../components/TitleInfoCard.vue";
import moduleMixin from "../../mixins/moduleMixin";
import {required} from "../../utils/validationRule";


export default {
  name: "kardexForm",
  mixins: [moduleMixin],
  components: {TitleInfoCard},
  props: {
    currentElement: {
      type: Object,
      default: () => {
      }
    },
    products: {
      type: Array,
      default: () => []
    },
    frequencies: {
      type: Array,
      default: () => []
    },
    subFrequencies: {
      type: Array,
      default: () => []
    },
    doseTypes: {
      type: Array,
      default: () => []
    },
    productUnits: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      required,
      text: '',
      validForm: false,
      addManually: false,
      addManuallyDays: false,
      selectedMonth: [],
      selectedDays: [],
      days: [
        {key: 'monday', value: 'Lunes'},
        {key: 'tuesday', value: 'Martes'},
        {key: 'wednesday', value: 'Miércoles'},
        {key: 'thursday', value: 'Jueves'},
        {key: 'friday', value: 'Viernes'},
        {key: 'saturday', value: 'Sábado'},
        {key: 'sunday', value: 'Domingo'}
      ],
      months: [
        {key: 'january', value: 'Enero'},
        {key: 'february', value: 'Febrero'},
        {key: 'march', value: 'Marzo'},
        {key: 'april', value: 'Abril'},
        {key: 'may', value: 'Mayo'},
        {key: 'june', value: 'Junio'},
        {key: 'july', value: 'Julio'},
        {key: 'august', value: 'Agosto'},
        {key: 'september', value: 'Septiembre'},
        {key: 'october', value: 'Octubre'},
        {key: 'november', value: 'Noviembre'},
        {key: 'december', value: 'Diciembre'}
      ],
      menu2: false,
      time: null,
    }
  },
  mounted() {
    this.fillDaysMonths()
    if (this.currentElement.quantity && (this.currentElement.quantity !== 0.25 && this.currentElement.quantity !== 0.50 && this.currentElement.quantity !== 1)) {
      this.addManually = true
    }
  },
  computed: {
    setQuantity() {
      return this.currentElement.quantity !== 0.25 || this.currentElement.quantity !== 0.5 || this.currentElement.quantity !== 1;
    },
    setDaily() {
      return this.currentElement.dailyDoseTime !== 'morning' || this.currentElement.quantity !== 'afternoon' || this.currentElement.dailyDoseTime !== 'night';
    },
  },
  methods: {
    fillDaysMonths() {
      this.selectedMonth = []
      this.selectedDays = []
      if (Array.isArray(this.currentElement?.monthsOfYear)) {
        this.selectedMonth = this.months.map((item) => {
          return {
            value: this.currentElement.monthsOfYear.includes(item.key),
            name: item.key
          }
        })
      } else {
        this.selectedMonth = this.months.map((item) => {
          return {
            value: false,
            name: item.name
          }
        });
      }

      if (Array.isArray(this.currentElement?.daysOfWeek)) {
        this.selectedDays = this.days.map((item) => {
          return {
            value: this.currentElement?.daysOfWeek.includes(item.key),
            name: item.key
          }
        })
      } else {
        this.selectedDays = this.days.map((item) => {
          return {
            value: false,
            name: item.name
          }
        });
      }
    },
    inputForm(e) {
      this.$emit("validFormInput", e);
    },
    async getProductUnits() {
      this.$emit('updateProductUnit')
    },
    selectQuantity() {
      this.addManually = false
    },
    selectDailyDoses() {
      this.currentElement.doseTime = null
      this.addManuallyDays = false
    },
    selectManually() {
      this.currentElement.quantity = 0
    },
    selectManuallyDays() {
      this.currentElement.dailyDoseTime = null
    },
    cleanDaysInterval() {
      this.currentElement.daysInterval = null
      this.currentElement.dailyDoseTime = null
      this.currentElement.doseTime = null
      this.addManuallyDays = false
    },
    cleanFrequency() {
      this.currentElement.doseTime = null
      this.currentElement.subFrequency = null
      this.currentElement.daysOfWeek = null
      this.currentElement.monthsOfYear = null
      this.currentElement.hoursInterval = null
      this.currentElement.monthsInterval = null
      this.currentElement.weeksInterval = null
      this.cleanDaysInterval()
      this.fillDaysMonths()
    },
    selectDays(event, item) {
      if (!Array.isArray(this.currentElement?.daysOfWeek) || !this.currentElement.daysOfWeek.length) {
        this.currentElement.daysOfWeek = [];
      }

      const foundItemIndex = this.currentElement.daysOfWeek.findIndex((key) => key === item.key);
      if (foundItemIndex !== -1) {
        this.currentElement.daysOfWeek.splice(foundItemIndex, 1);
      } else {
        this.currentElement.daysOfWeek.push(item.key);
      }

      const foundItemIndexSelectedDays = this.selectedDays.findIndex(({name}) => name === item.key)
      if (foundItemIndexSelectedDays !== -1) {
        return
      }
      this.selectedDays.push({name: item.value, value: true})
    },
    selectMonths(event, item) {
      try {
        console.log(event, item)
        if (!Array.isArray(this.currentElement?.monthsOfYear) || !this.currentElement.monthsOfYear.length) {
          this.currentElement.monthsOfYear = [];
        }

        const foundItemIndex = this.currentElement.monthsOfYear.findIndex((key) => key === item.key);
        if (foundItemIndex !== -1) {
          this.currentElement.monthsOfYear.splice(foundItemIndex, 1);
        } else {
          this.currentElement.monthsOfYear.push(item.key);
        }

        const foundItemIndexSelectedMonth = this.selectedMonth.findIndex(({name}) => name === item.key)
        if (foundItemIndexSelectedMonth !== -1) {
          return
        }
        this.selectedMonth.push({name: item.value, value: true})
        console.log(this.selectedMonth)
      } catch (error) {
        console.log(error)
      }
    },
    formatDate(value) {
      if (!value) {
        return value
      }
      value = value.replace(/[^\d/]/g, '');
      const dateValue = value.replace(/\D/g, '')
      const dateValueLength = dateValue?.length
      if (dateValueLength < 2) return dateValue
      if (dateValueLength < 4) {
        return `${dateValue.slice(0, 2)}/${dateValue.slice(2, 4)}`
      }
      return `${dateValue.slice(0, 2)}/${dateValue.slice(2, 4)}/${dateValue.slice(4, 8)}`
    },
    dateFormatter(event) {
      event.target.value = this.formatDate(event.target.value)
    },
    cleanDoseType() {
      this.currentElement.startDateTime = null
      this.currentElement.endDateTime = null
      this.currentElement.pillCount = null
    }
  }
}
</script>

<style scoped>
.read-only-text {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}

.read-only-text-child {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}
</style>
